import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Produtos from "../../components/Produtos";

const Cardapio = () => {
  return (
    <>
      <Header />
      <div className="mt-24">
        <Produtos />
      </div>

      <Footer />
    </>
  );
}

export default Cardapio;