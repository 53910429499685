import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import logoImage from "../images/logo-bar.png";

const Footer = () => {
    return (
        <footer className="bg-black text-white py-10">

            <div className="container mx-auto flex flex-col sm:flex-row items-center justify-evenly">
                {/* Left section - Logo */}
                <div className="flex flex-col items-center justify-center mb-6 sm:mb-0">
                    <Link to={"/"}>
                        <img
                            src={logoImage}
                            alt="Vitrine Long Bar Logo"
                            className="h-36 w-36 object-contain min-h-32 min-w-32"
                        />
                    </Link>
                    <p className='text-sm opacity-25'>© 2024 Vitrine Long Bar.</p>
                    <p className='text-sm opacity-25'>All rights reserved.</p>
                </div>

                <div className="w-full sm:w-auto">
                    {/* Center section - Social media */}
                    <div className="flex flex-col sm:flex-row sm:space-x-10 items-center sm:items-start mb-6 sm:mb-0 opacity-80 text-center sm:text-start">
                        <div>
                            <h3 className="font-bold mb-1">Redes Sociais</h3>
                            <ul className="space-y-2 opacity-80">
                                <li className="flex items-center justify-center sm:justify-start">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                    <a href="mailto:contato@vitrinelongbar.com">
                                        contato@vitrinelongbar.com
                                    </a>
                                </li>
                                <li className="flex items-center justify-center sm:justify-start">
                                    <FontAwesomeIcon icon={faPhone} className="mr-2" />
                                    <a href="tel:+551190182608">(11) 99018-2608</a>
                                </li>
                                <li className="flex items-center justify-center sm:justify-start">
                                    <FontAwesomeIcon icon={faInstagram} className="mr-2" />
                                    <a
                                        href="https://instagram.com/vitrinelongbar"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        VitrineLongBar
                                    </a>
                                </li>
                                <li className="flex items-center justify-center sm:justify-start">
                                    <FontAwesomeIcon icon={faFacebook} className="mr-2" />
                                    <a
                                        href="https://facebook.com/vitrinelongbar"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Vitrine Long Bar
                                    </a>
                                </li>
                            </ul>
                        </div>

                        {/* Right section - Menu */}
                        <div>
                            <h3 className="font-bold mb-1 query-ajust:mt-10 sm:mt-0">Menu</h3>
                            <ul className="space-y-2 opacity-80">
                                <Link to={"/"}>
                                    <li><a href="/">Home</a></li>
                                </Link>
                                <Link to={"/cardapio"}>
                                    <li><a href="/cardapio">Cardápio</a></li>
                                </Link>
                                <Link to={"/contato"}>
                                    <li><a href="/contato">Contato</a></li>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
