import { useState } from 'react';
import { Link } from 'react-router-dom';
import logoImage from "../images/logo-bar.png"
import greyDots from "../images/grey-dot.png"



const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="bg-black md:pr-20 md:pl-28 query-ajust:px-4 fixed z-50 top-0 left-0 right-0">
            <div className="flex items-center bg-header-grey query-ajust:py-3 py-1 px-6 lg:justify-between query-ajust:justify-end ">

                {/* Logo */}
                <div className="lg:m-3 query-ajust:mr-auto">
                    <Link to={"/"}>
                        <img src={logoImage} className='size-16' alt="Logo Page" />
                    </Link>
                </div>

                {/* Dropdown Btn */}
                <div className="block lg:hidden order-2 md:order-2 sm:order-2 items-center bg-header-grey align-middle">
                    <button onClick={toggleMenu} className="text-white focus:outline-none">
                        <svg className="w-6 h-6 mt-2 bg-header-grey" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                </div>



                {/* Right Header */}
                <div className="query-ajust:hidden lg:w-auto lg:flex lg:items-center bg-black-text ">
                    <Link to={"/"}>
                        <a className="text-white text-sm">Pagina inicial</a>
                    </Link>
                    <img src={greyDots} className='m-2' />
                    <Link to={"/cardapio"}>
                        <a className="text-white text-sm">Cardapio</a>
                    </Link>

                    <img src={greyDots} className='m-2' />

                    <Link to={"/contato"}>
                        <a className="text-white text-sm">Contato</a>
                    </Link>
                </div>

            </div>
            {/* Nav in Dropdown */}
            <div id="menu" className={`menu pb-5 bg-header-grey mx-7 mt-3 ${isMenuOpen ? '' : 'hidden'}`}>
                <Link to={"/"}>
                    <a className="text-white block hover:text-hyla-blue duration-300 mb-4" href="#">Home</a>
                </Link>

                <Link to={"/cardapio"}>
                    <a className="text-white block hover:text-hyla-blue duration-300 mb-4" href="#">Cardapio</a>
                </Link>

                <Link to={"/contato"}>
                    <a className="text-white block hover:text-hyla-blue duration-300 mb-4" href="#">Contato</a>
                </Link>
            </div>
        </div>
    )
}

export default Header;
