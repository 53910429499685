import Header from "../../components/Header";
import logoImage from "../../images/logo-bar.png";
import backgroundImage from "../../images/paredetijolos.png";
import Footer from "../../components/Footer";
import { Link } from 'react-router-dom';

import cardapioIcon from "../../images/cardapio-icon.png"



const Home = () => {
  return (
    <>
      <body>
        <Header />
        <main className="md:mt-14 query-ajust:mt-20">
          <div className="relative">
            <div className="absolute inset-0 flex justify-center items-center">
              <img
                className="z-10 w-[30%] sm:w-[30%] md:w-[30%] lg:w-[30%] xl:w-[30%]"
                src={logoImage}
                alt="Logo"
              />
            </div>
            <div>
              <img
                className="w-full h-full object-cover"
                src={backgroundImage}
                alt="Background"
              />
            </div>
          </div>
          <div className="bg-vitrine-endbox w-full h-auto pt-10 pb-10 px-6 sm:px-10 md:px-16 lg:px-32">
            <div className="mt-20 mb-36">
              <div className="text-white mb-28">
                <h1 className="text-center font-bold text-[26px] sm:text-[32px] md:text-[36px] lg:text-[40px]">
                  Explore nosso{" "}
                  <span className="text-vitrine-font-endbox">Cardápio</span>
                </h1>
                <div className="flex justify-center mt-3">
                  <p className="mt-6 mb-6 max-w-[900px] text-center font-extralight text-[16px] query-ajust:text-[18px] md:text-[20px]">
                    Na Vitrine Long Bar, você encontrará uma seleção especial de
                    drinks, cervejas e narguile, tudo preparado para
                    proporcionar momentos únicos de descontração. Seja para
                    relaxar com amigos ou curtir uma noite animada, explore
                    nosso cardápio e descubra sabores que fazem toda a
                    diferença.
                  </p>
                </div>
                <div className="flex justify-center mt-6">
                  <Link to={'/cardapio'}>
                  <button className="w-[200px] query-ajust:w-[216px] h-[50px] bg-vitrine-font-endbox flex justify-between items-center px-4 query-ajust:px-6 rounded-md align-middle">
                    <h1 className="text-white font-semibold text-[18px] query-ajust:text-[20px]">
                      Ver cardápio
                    </h1>
                    <img src={cardapioIcon} className="size-7"/>
                  </button>
                  </Link>
                </div>
              </div>

              <div>
                <div className="text-white flex flex-col items-center">
                  <h1 className="text-center font-bold text-[26px] sm:text-[32px] md:text-[36px] lg:text-[40px]">
                    Um pouco da nossa{" "}
                    <span className="text-vitrine-font-endbox">História</span>
                  </h1>
                  {/* <div className="bg-vitrine-endbox w-[351px] h-[192px] mt-6 flex justify-center items-center xl:hidden"></div> */}

                  <div className="flex justify-center mt-3">
                    <p className="mt-6 mb-6 max-w-[800px] text-left font-extralight text-[16px] sm:text-[18px] md:text-[20px]">
                      A Vitrine Long Bar nasceu da paixão por criar experiências
                      inesquecíveis em um ambiente acolhedor e moderno. Desde a
                      nossa inauguração, nos dedicamos a oferecer aos nossos
                      clientes drinks inovadores, cervejas artesanais
                      selecionadas e uma experiência diferenciada com o
                      narguile. Cada detalhe foi pensado para ser um ponto de
                      encontro perfeito para relaxar e aproveitar momentos
                      especiais, sempre com um atendimento que faz você se
                      sentir em casa. Venha fazer parte da nossa história e
                      brindar com a gente!
                    </p>
                    {/* <div className="bg-box-gray-home w-[351px] h-[192px] ml-6 mt-5 query-ajust:hidden xl:block"></div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-auto pt-10 pb-10 px-6 sm:px-10 md:px-16 lg:px-32">
              <div className="flex flex-col lg:flex-row justify-between">
                <div className="text-white mb-6 lg:mb-0">
                  <h1 className="font-bold text-[26px] sm:text-[32px] md:text-[36px] lg:text-[40px]">
                    Venha{" "}
                    <span className="text-vitrine-font-endbox">beber</span> com
                    a gente!
                  </h1>
                  <p className="font-extralight text-[16px] sm:text-[18px] md:text-[20px]">
                    R. Dom Manuel D'Elboux, 39 - Vila Pereira Cerca{" "}
                    <span className="text-vitrine-font-endbox font-bold">
                      São Paulo - SP
                    </span>
                  </p>
                </div>
                <div className="text-white text-[16px] sm:text-[18px] md:text-[20px]">
                  <h1 className="font-bold">Horário de Funcionamento:</h1>
                  <p className="font-extralight">
                    Terça à Quinta -{" "}
                    <span className="text-vitrine-font-endbox font-semibold">
                      16h
                    </span>{" "}
                    às{" "}
                    <span className="text-vitrine-font-endbox font-semibold">
                      00h
                    </span>
                  </p>
                  <p className="font-extralight">
                    Sexta à Domingo -{" "}
                    <span className="text-vitrine-font-endbox font-semibold">
                      15h
                    </span>{" "}
                    às{" "}
                    <span className="text-vitrine-font-endbox font-semibold">
                      02h
                    </span>
                  </p>
                </div>
              </div>
              <div className="mt-8"></div>
              <iframe
                className="h-full sm:h-[250px] md:h-[300px] lg:h-[351px] w-full"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.250420063106!2d-46.7135634!3d-23.487488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef908d6ee1661%3A0x6bf320b69bf7be4b!2sRua%20Dom%20Manuel%20D&#39;Elboux%2C%2039%20-%20Vila%20Pereira%20Cerca%2C%20São%20Paulo%20-%20SP%2C%2002932-110!5e0!3m2!1spt-BR!2sbr!4v1725637518415!5m2!1spt-BR!2sbr"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps"
              />
            </div>
          </div>
        </main>
        <Footer />
      </body>
    </>
  );
};

export default Home;
