import { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Contact = () => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    empresa: "",
    telefone: "",
    assunto: "",
    mensagem: "",
  });
  
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError(false);
    
    try {
      const response = await fetch("https://formsubmit.co/ajax/contato@vitrinelongbar.com", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (data.success) {
        setSuccess(true);
        setFormData({
          nome: "",
          email: "",
          empresa: "",
          telefone: "",
          assunto: "",
          mensagem: "",
        });
      } else {
        throw new Error("Error sending message");
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <main className="">
        <div className="mt-32 mb-10">
          <div className="text-white flex justify-center items-center">
            <div className="text-center mx-2">
              <h1 className="font-bold query-ajust:text-[26px] md:text-[36px] lg:text-[40px]">
                Vamos conversar? Mande um{" "}
                <span className="text-vitrine-font-endbox">e-mail</span>!
              </h1>
              <p className="font-extralight text-[18px] sm:text-[20px] md:text-[22px]">
                Ou entre em contato conosco através de:{" "}
                <span className="text-vitrine-font-endbox">
                  contato@vitrinelongbar.com
                </span>
              </p>

              {/* Formulário */}
              <div className="mt-7 flex justify-center ">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="flex-1 mx-2 md:mx-0">
                      <input
                        className="bg-transparent outline-none border-[1px] p-2 query-ajust:w-[300px] sm:w-[500px] md:w-[300px] rounded-[0.20rem]"
                        placeholder="Nome"
                        type="text"
                        id="nome"
                        value={formData.nome}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="flex-1 mx-2 mt-4 md:mt-0 md:mx-0">
                      <input
                        className="bg-transparent outline-none border-[1px] p-2 w-full md:w-[300px] rounded-[0.20rem]"
                        placeholder="E-mail"
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex flex-col md:flex-row md:space-x-4">
                    <div className="flex-1 mx-2 md:mx-0">
                      <input
                        className="bg-transparent outline-none border-[1px] p-2 w-full md:w-[300px] rounded-[0.20rem]"
                        placeholder="Empresa"
                        type="text"
                        id="empresa"
                        value={formData.empresa}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex-1 mx-2 mt-4 md:mt-0 md:mx-0">
                      <input
                        className="bg-transparent outline-none border-[1px] p-2 w-full md:w-[300px] rounded-[0.20rem]"
                        placeholder="Telefone"
                        type="tel"
                        id="telefone"
                        value={formData.telefone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mt-4 mx-2 md:mx-0">
                    <input
                      placeholder="Assunto"
                      type="text"
                      id="assunto"
                      className="bg-transparent outline-none border-[1px] p-2 w-full md:w-[632px] rounded-[0.20rem]"
                      value={formData.assunto}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mt-4 mx-2 md:mx-0">
                    <textarea
                      placeholder="Mensagem"
                      id="mensagem"
                      rows="4"
                      className="bg-transparent outline-none border-[1px] resize-none p-2 w-full md:w-[632px] rounded-[0.20rem]"
                      value={formData.mensagem}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-vitrine-font-endbox mt-2 rounded-[0.20rem] text-white font-medium py-2 w-full md:w-[632px]"
                  >
                    {loading ? "Enviando..." : "Enviar"}
                  </button>
                  {success && <p className="text-green-500">Mensagem enviada com sucesso!</p>}
                  {error && <p className="text-red-500">Houve um erro ao enviar a mensagem. Tente novamente.</p>}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-vitrine-endbox w-full h-auto pt-10 pb-10 px-6 sm:px-10 md:px-16 lg:px-32">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="text-white mb-6 lg:mb-0">
              <h1 className="font-bold text-[26px] sm:text-[32px] md:text-[36px] lg:text-[40px]">
                Venha <span className="text-vitrine-font-endbox">beber</span>{" "}
                com a gente!
              </h1>
              <p className="font-extralight text-[16px] sm:text-[18px] md:text-[20px]">
                R. Dom Manuel D'Elboux, 39 - Vila Pereira Cerca{" "}
                <span className="text-vitrine-font-endbox font-bold">
                  São Paulo - SP
                </span>
              </p>
            </div>
            <div className="text-white text-[16px] sm:text-[18px] md:text-[20px]">
              <h1 className="font-bold">Horário de Funcionamento:</h1>
              <p className="font-extralight">
                Terça à Quinta -{" "}
                <span className="text-vitrine-font-endbox font-semibold">
                  16h
                </span>{" "}
                às{" "}
                <span className="text-vitrine-font-endbox font-semibold">
                  00h
                </span>
              </p>
              <p className="font-extralight">
                Sexta à Domingo -{" "}
                <span className="text-vitrine-font-endbox font-semibold">
                  15h
                </span>{" "}
                às{" "}
                <span className="text-vitrine-font-endbox font-semibold">
                  02h
                </span>
              </p>
            </div>
          </div>

          <div className="mt-8">
            <iframe
              className="h-full sm:h-[250px] md:h-[300px] lg:h-[351px] w-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.250420063106!2d-46.7135634!3d-23.487488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef908d6ee1661%3A0x6bf320b69bf7be4b!2sRua%20Dom%20Manuel%20D'Elboux%2C%2039%20-%20Vila%20Pereira%20Cerca%2C%20São%20Paulo%20-%20SP%2C%2002932-110!5e0!3m2!1spt-BR!2sbr!4v1725637518415!5m2!1spt-BR!2sbr"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Contact;