import React, { useState } from "react";




import aguaFoto from "../images/products/agua.png";
import aguaGasFoto from "../images/products/aguagas.png";
import aluminioFoto from "../images/products/aluminio.png";
import adaliaFoto from "../images/products/adalia.png";
import buchanansFoto from "../images/products/buchanans.png";
import buchanansComboFoto from "../images/products/buchananscombo.png";
import aluminioCaixaFoto from "../images/products/caixaaluminio.png";
import carvaoCaixaFoto from "../images/products/caixacarvao.png";
import carvaoFoto from "../images/products/carvao.png";
import cavaloFoto from "../images/products/cavalo.png";
import cavaloComboFoto from "../images/products/cavalocombo.png";
import entityFoto from "../images/products/entity.png";
import gineternityFoto from "../images/products/gineternity.png";
import goldFoto from "../images/products/gold.png";
import jackFoto from "../images/products/jack.png";
import jackComboFoto from "../images/products/jackcombo.png";
import nayFoto from "../images/products/nay.png";
import redFoto from "../images/products/red.png";
import redbullFoto from "../images/products/redbull.jpg";
import refrigeranteFoto from "../images/products/refrigerante.png";
import sucoFoto from "../images/products/suco.png";
import tanquerayFoto from "../images/products/tanqueray.png";
import ziggyFoto from "../images/products/ziggy.png";
import zomoFoto from "../images/products/zomo.png";





const Produtos = ({ product }) => {
    if (!product) return null;
  
    return (
      <div className="border-2 border-opacity-30 rounded-lg text-white p-4 flex justify-between items-center w-full max-w-[370px] min-w-[370px] max-h-[128px] min-h-[128px]">
        <div>
          <h3 className="text-lg font-semibold">{product.name}</h3>
          <p className="text-sm text-gray-300">{product.description}</p>
          <p className="mt-4 text-lg font-bold">{product.price}</p>
        </div>
        <div className="ml-4">
          <img
            src={product.image}
            alt={product.name}
            className="h-20 w-20 object-cover rounded-lg"
          />
        </div>
      </div>
    );
  };
  
  const ProductSection = ({ title, products }) => {
    return (
      <div className="mb-8">
        <h2 className="text-xl font-semibold text-white mb-4">{title}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {products.map((product, index) => (
            <Produtos key={index} product={product} />
          ))}
        </div>
      </div>
    );
  };
  
  // Componente principal da lista de produtos
  const ProductList = () => {
    const [selectedSection, setSelectedSection] = useState(0); // Controla a aba visível
  
    // Definição das seções e produtos
    const sections = [
      {
        title: "Sem álcool",
        products: [
          {
            name: "Suco Del Valle",
            description: "Consultar opções de suco",
            price: "R$ 7,00",
            image: sucoFoto,
          },
          {
            name: "Refrigerantes lata",
            description: "Consultar opções de refrigerante",
            price: "R$ 6,00",
            image: refrigeranteFoto,
          },
          {
            name: "Água",
            description: "",
            price: "R$ 3,00",
            image: aguaFoto,
          },
          {
            name: "Água com gás",
            description: "",
            price: "R$ 4,00",
            image: aguaGasFoto,
          },
          {
            name: "RedBull",
            description: "",
            price: "R$ 14,00",
            image: redbullFoto,
          },
        ],
      },
      {
        title: "Doses",
        products: [
          {
            name: "Cavalo Branco",
            description: "",
            price: "R$25,00/Vibe e R$30,00/RedBull",
            image: cavaloFoto,
          },
          {
            name: "Red Label",
            description: "",
            price: "R$35,00/Vibe e R$40,00/RedBull",
            image: redFoto,
          },
          {
            name: "Jack Daniels",
            description: "",
            price: "R$45,00/Vibe e R$50,00/RedBull",
            image: jackFoto,
          },
          {
            name: "Buchanans",
            description: "",
            price: "R$50,00/Vibe e R$55,00/RedBull",
            image: buchanansFoto,
          },
          {
            name: "Gold Label",
            description: "",
            price: "R$65,00/Vibe e R$70,00/RedBull",
            image: goldFoto,
          },
          {
            name: "Gin Tanqueray",
            description: "",
            price: "R$35,00/Vibe e R$40,00/Red Bull",
            image: tanquerayFoto,
          },
          {
            name: "Gin Eternity",
            description: "",
            price: "R$20,00",
            image: entityFoto,
          },
          {
            name: "Gin LM",
            description: "",
            price: "R$15,00",
            image: entityFoto,
          },
        ],
      },
      {
        title: "Combos",
        products: [
          {
            name: "Jack Daniels",
            description: "Whiskey, energético, gelo.",
            price: "R$300,00",
            image: jackComboFoto,
          },
          {
            name: "Buchanans",
            description: "Whiskey, energético, gelo.",
            price: "R$350,00",
            image: buchanansComboFoto,
          },
          {
            name: "Gin Eternity",
            description: "Gin, energético e gelo.",
            price: "R$75,00",
            image: gineternityFoto,
          },
          {
            name: "Gin Tanqueray",
            description: "Gin, energético e gelo.",
            price: "R$220,00",
            image: tanquerayFoto,
          },
          {
            name: "Cavalo Branco",
            description: "Whiskey, energético, gelo.",
            price: "R$155,00",
            image: cavaloComboFoto,
          },
        ],
      },
      {
        title: "Acessórios Para Narguilé",
        products: [
            {
                name: "Carvão Solto",
                description: "Unidade do carvão",
                price: "R$ 1,50",
                image: carvaoFoto, 
            },
            {
                name: "Caixa de Carvão",
                description: "Caixa do carvão",
                price: "R$ 45,00",
                image: carvaoCaixaFoto,
            },
            {
                name: "Alumínio Solto",
                description: "Unidade do alumínio",
                price: "R$ 1,00",
                image: aluminioFoto,
            },
            {
                name: "Caixa de Alumínio",
                description: "Unidade de alumínio",
                price: "R$ 15,00",
                image: aluminioCaixaFoto, 
            },
        ],
      },
     
      {
        title: "Essências",
        products: [
          {
            name: "Ziggy",
            description: "",
            price: "R$ 20,00",
            image: ziggyFoto,
          },
          {
            name: "Zomo",
            description: "",
            price: "R$ 17,00",
            image: zomoFoto,
          },
          {
            name: "Adalya",
            description: "",
            price: "R$ 25,00",
            image: adaliaFoto,
          },
          {
            name: "Nay",
            description: "",
            price: "R$ 40,00",
            image: nayFoto,
          },
        ],
      },
    ];
  
    return (
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-3xl font-bold text-white mb-8 ml-8 mt-10">Vitrine Long Bar</h1>
    
          {/* Roda de seleção de seção na parte superior visível apenas em telas menores que md */}
          <div className="relative md:hidden overflow-x-auto whitespace-nowrap mb-4 w-full flex flex-col sm:justify-center sm:items-center query-ajust:justify-start">
            <div className="flex">
              {sections.map((section, index) => (
                <div
                  key={index}
                  className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${
                    selectedSection === index
                      ? 'border-b-2 border-red-500 text-red-500'
                      : 'border-b-2 border-transparent text-gray-400 hover:text-red-500 hover:border-red-500'
                  }`}
                  onClick={() => setSelectedSection(index)}
                >
                  {section.title}
                </div>
              ))}
            </div>
          </div>
    
          {/* Mostra a seção selecionada em telas menores */}
          <div className="md:hidden">
            <ProductSection
              title={sections[selectedSection].title}
              products={sections[selectedSection].products}
            />
          </div>
    
          {/* Mostra todas as seções em telas maiores */}
          <div className="hidden md:block">
            {sections.map((section, index) => (
              <ProductSection key={index} title={section.title} products={section.products} />
            ))}
          </div>
        </div>
      );
    };
    
    export default ProductList;